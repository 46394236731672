import config from "../config";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../auth/AuthProviderQ2";

const Clock = ({ initialTime }) => {
  const [timerId, setTimerId] = useState();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    var id = setInterval(() => {
      var now = new Date().getTime();
      var lastActivity = parseInt(sessionStorage["last_activity_time"]);
      var timeout =
        (isNaN(lastActivity) ? now : lastActivity) + config.bank.TIMEOUT;

      if (parseInt(timeout) < parseInt(now)) {
        logout();
      } else {
      }
    }, 1000);
    setTimerId(id);
  }, []);

  return (<></>);
};

export default Clock;
